import Main from "./Main";

const Home = () => {
    return (
        <>
            <Main />
        </>
       
    )
};

export default Home